// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-my-files-js": () => import("./../src/pages/my-files.js" /* webpackChunkName: "component---src-pages-my-files-js" */),
  "component---src-pages-work-js": () => import("./../src/pages/work.js" /* webpackChunkName: "component---src-pages-work-js" */),
  "component---src-pages-work-mixpanel-design-system-js": () => import("./../src/pages/work/mixpanel/design-system.js" /* webpackChunkName: "component---src-pages-work-mixpanel-design-system-js" */),
  "component---src-pages-work-mixpanel-email-ios-js": () => import("./../src/pages/work/mixpanel/email-ios.js" /* webpackChunkName: "component---src-pages-work-mixpanel-email-ios-js" */),
  "component---src-pages-work-mixpanel-insights-js": () => import("./../src/pages/work/mixpanel/insights.js" /* webpackChunkName: "component---src-pages-work-mixpanel-insights-js" */)
}

